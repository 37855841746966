/*eslint-disable*/

import actionConstants from '../constants/ActionConstants';
import moment from 'moment';

const DEFAULT_ASYNC_STATE = {
  isSuccess: false,
  isError: false,
  shouldNotUpdate: false,
  message: '',
};

const sections = {
  'Alarm': {
    monitoring: {},
    controlPanel: [],
    finish: {},
    device: []
  }
}

const DEFAULT_CLIENT_STATE = {
  isSuccess: false,
  isError: false,
  isFetching: false,
  clientData: {}
}

const initialState = {
  answerState: {},
  missedItems: {},
  error: '',
  isFetching: false,
  success: '',
  isSuccess: false,
  isDeviceAdded: false,
  isDeviceDragged: false,
  isSync: false,
  selectedFrequency: null,
  inspectionAnswer: {},
  jsonData: {},
  scannedQR: null,
  reportData: [],
  mainAnswerData: [],
  answerData: [],
  missedData: [],
  createdFor: null,
  signatureData: [],
  updateKey: false,
  keyToUpdate: '',
  getDeviceListApi: {
    error: '',
    isfetching: false,
    success: '',
    deviceListData: [],
    isSuccess: false,
    totalCount: 0,
    totalPages: 0,
    currentPage: 0,
  },
  clientDetails: {
    ...DEFAULT_CLIENT_STATE
  },
  generateNewRowState: {
    ...DEFAULT_ASYNC_STATE,
  },

  shiftRowState: {
    ...DEFAULT_ASYNC_STATE
  },
  deleteRowState: {
    ...DEFAULT_ASYNC_STATE
  }

};

export default (state = initialState, action) => {
  let newQuestions = {};
  switch (action.type) {
    case actionConstants.UPDATE_REAL_QUESTION:
      const index = state.reportData.findIndex(x => x._id.toString() === action.payload._id.toString());
      return {
        ...state,
        reportData: [...state.reportData.slice(0, index),
        {
          ...state.reportData[index],
          questionJson: action.payload.questionJson
        },
        ...state.reportData.slice(index + 1)
        ]
      }
    case actionConstants.FETCH_REPORT_DETAIL_SUCCESS:
      if (action.payload.reportType === 'Inspection' && !action.extra && action.payload.questionJson) {
        const index = state.reportData.findIndex(x => x._id.toString() === action.payload._id.toString());
        if (index > -1) {
          return {
            ...state,
            reportData: [
              ...state.reportData.slice(0, index),
              { ...state.reportData[index], ...action.payload },
              ...state.reportData.slice(index + 1),
            ],
            mainAnswerData: [
              ...state.mainAnswerData.slice(0, index),
              Object.keys(action.payload.answers).length > 0 ? action.payload.answers : { ...sections[action.payload.reportCategory] },
              ...state.mainAnswerData.slice(index + 1)
            ],
            answerData: [
              ...state.answerData.slice(0, index),
              Object.keys(action.payload.answers).length > 0 ? action.payload.answers : { ...sections[action.payload.reportCategory] },
              ...state.answerData.slice(index + 1)
            ],
            missedData: [
              ...state.missedData.slice(0, index),
              action.payload.missedItems ? action.payload.missedItems : {},
              ...state.missedData.slice(index + 1)
            ]
          };
        }
        return {
          ...state,
          reportData: [
            ...state.reportData,
            action.payload,
          ],
          mainAnswerData: [
            ...state.mainAnswerData,
            Object.keys(action.payload.answers).length > 0 ? action.payload.answers : { ...sections[action.payload.reportCategory] },
          ],
          answerData: [
            ...state.answerData,
            Object.keys(action.payload.answers).length > 0 ? action.payload.answers : { ...sections[action.payload.reportCategory] },
          ],
          missedData: [
            ...state.missedData,
            action.payload.missedItems ? action.payload.missedItems : {}
          ]
        };
      }
      return {
        ...state
      };
    case actionConstants.UPDATE_SYNC_REPORT:
      return {
        ...state,
        answerData: [...JSON.parse(JSON.stringify(action.payload.answerData))],
        mainAnswerData: [...JSON.parse(JSON.stringify(action.payload.answerData))]
      }
    case actionConstants.REVERSE_SYNC_REPORT:
      return {
        ...state,
        answerData: [...JSON.parse(JSON.stringify(state.mainAnswerData))]
      }
    case actionConstants.SYNC_REPORT:
      return {
        ...state,
        mainAnswerData: [...JSON.parse(JSON.stringify(state.answerData))],
        answerData: [...JSON.parse(JSON.stringify(state.answerData))],
      }
    case actionConstants.CLEAR_SYNC_REPORT:
      return {
        ...state,
        isSync: false
      };

    case actionConstants.SET_INSPECTION_VIEW_ANSWERS_AND_JSON_DEFAULT_REPORTS:
      return {
        ...state,
        answerState: {
          ...action.payload.answers
        },
        jsonData: {
          ...action.payload.jsonData
        },
        finishReport: action.payload.finishReport,
        createdFor: action.payload.createdFor,
        selectedFrequency: action.payload.selectedFrequency
      };
    case actionConstants.INSERT_ANSWERS_HARDCODED_REPORTS:
      let oldDetails = state.answerData[action.payload.selectedIndex][action.payload.sectionName] && state.answerData[action.payload.selectedIndex][action.payload.sectionName][action.payload.key] ? state.answerData[action.payload.selectedIndex][action.payload.sectionName][action.payload.key] : {}
      let newAnswers = {
        ...state.answerData[action.payload.selectedIndex][action.payload.sectionName],
        [action.payload.key]: {
          ...oldDetails,
          // ...[action.payload.key],
          isMemorized: action.payload.isMemorized,
          ans: action.payload.value,
          type: action.payload.type,
          isOther: action.payload.isOther
        }
      };
      if (action.payload.extras) {
        newAnswers = { ...action.payload.extras };
      }
      return {
        ...state,
        mainAnswerData: action.isSync ? [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: {
              ...newAnswers,
            }
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ] : state.mainAnswerData,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: {
              ...newAnswers,
            }
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ]
      }
    case actionConstants.UNSET_HARDCODED_CHILD_ANSWERS:
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: {
              ...state.answerData[action.payload.selectedIndex][action.payload.sectionName],
              [action.payload.key]: undefined
            }
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ]
      }
    case actionConstants.SET_OFF_SWITCHES:
      let oldDetail = state.answerData[action.payload.selectedIndex][action.payload.sectionName] && state.answerData[action.payload.selectedIndex][action.payload.sectionName][action.payload.key] ? state.answerData[action.payload.selectedIndex][action.payload.sectionName][action.payload.key] : {}
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: {
              ...state.answerData[action.payload.selectedIndex][action.payload.sectionName],
              [action.payload.key]: {
                ...oldDetail,
                [action.payload.id]: action.payload.value,
                isMemorized: !!action.payload.isMemorized
              }
            }
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ]
      }
    case actionConstants.ADD_HARDCODED_DEVICE:
      const oldDevices = state.answerData[action.payload.selectedIndex][action.payload.sectionName] ?
        state.answerData[action.payload.selectedIndex][action.payload.sectionName] : []
      return {
        ...state,
        isSync: action.isSync,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: [
              ...oldDevices,
              {
                ...action.payload.deviceDetails
              }
            ]
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ]
      }

    case actionConstants.GET_DEVICES_FOR_HARDCODED_REPORT_SUCCESS:
      if (action.payload.page == 1) {
        return {
          ...state,
          getDeviceListApi: {
            ...state.getDeviceListApi,
            isSuccess: true,
            isfetching: false,
            deviceListData: [...action.payload.docs],
            totalPages: action.payload.pages,
            totalCount: action.payload.total,
            currentPage: action.payload.page
          }
        }
      }
      return {
        ...state,
        getDeviceListApi: {
          ...state.getDeviceListApi,
          isSuccess: true,
          isfetching: false,
          deviceListData: [...state.getDeviceListApi.deviceListData, ...action.payload.docs],
          totalPages: action.payload.pages,
          totalCount: action.payload.total,
          currentPage: action.payload.page
        }
      }
    case actionConstants.CLEAR_DEVICES_HARDCODED_REPORT_SUCCESS: {
      return {
        ...state,
        getDeviceListApi: {
          ...state.getDeviceListApi,
          isSuccess: false,
          error: ''
        }
      }
    }

    case actionConstants.GET_DEVICES_FOR_HARDCODED_REPORT_FAILURE: {
      return {
        ...state,
        getDeviceListApi: {
          ...state.getDeviceListApi,
          isSuccess: false,
          isfetching: false,
          error: action.message
        }
      }
    }

    case actionConstants.GET_DEVICES_FOR_HARDCODED_REPORT_REQUEST:
      return {
        ...state,
        getDeviceListApi: {
          ...state.getDeviceListApi,
          isSuccess: false,
          isfetching: true,
          error: ''
        }
      };

    case actionConstants.CLEAR_DEVICE_HARDCODED_REPORT_DATA:
      return {
        ...state,
        getDeviceListApi: {
          error: '',
          isfetching: false,
          success: '',
          deviceListData: [],
          isSuccess: false,
          totalCount: 0,
          totalPages: 0,
          currentPage: 0,
        },

      };
    case actionConstants.REPLACE_HARDCODED_DEVICES:
      return {
        ...state,
        isSync: action.isSync,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: [...action.payload.devices]
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ]
      }
    case actionConstants.UPDATE_PARTICULAR_INDEX_DEVICE_DETAIL:
      let oldDeviceDetail = [...state.answerData[action.payload.selectedIndex][action.payload.sectionName]];
      const timeStamp = {};
      if (action.payload.timeStamp) {
        timeStamp.timeStamp = action.payload.timeStamp;
      }
      let newDeviceDetails = {
        [action.payload.key]: {
          ...state.answerData[action.payload.selectedIndex][action.payload.sectionName][action.payload.deviceIndex][action.payload.key],
          [action.payload.id]: action.payload.value,
          type: action.payload.type,
          isMemorized: !!action.payload.isMemorized,
          ...timeStamp,
        }
      };
      if (action.payload.extras) {
        newDeviceDetails = { ...action.payload.extras };
      }
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: [
              ...oldDeviceDetail.slice(0, action.payload.deviceIndex),
              {
                ...state.answerData[action.payload.selectedIndex][action.payload.sectionName][action.payload.deviceIndex],
                ...newDeviceDetails,
              },
              ...oldDeviceDetail.slice(action.payload.deviceIndex + 1),
            ]
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ]
      }
    case actionConstants.EDIT_PARTICULAR_DEVICE:
      let oldDeviceDetails = [...state.answerData[action.payload.selectedIndex][action.payload.sectionName]];

      return {
        ...state,
        isSync: action.isSync,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: [
              ...oldDeviceDetails.slice(0, action.payload.deviceIndex),
              {
                ...action.payload.deviceDetails
              },
              ...oldDeviceDetails.slice(action.payload.deviceIndex + 1),
            ]
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ]
      }
    case actionConstants.ADD_CUSTOM_QUESTION:
      return {
        ...state,
        reportData: [
          ...state.reportData.slice(0, action.payload.selectedIndex),
          {
            ...state.reportData[action.payload.selectedIndex],
            questionJson: {
              ...state.reportData[action.payload.selectedIndex].questionJson,
              [action.payload.sectionName]: {
                ...state.reportData[action.payload.selectedIndex].questionJson[action.payload.sectionName],
                questions: [
                  ...state.reportData[action.payload.selectedIndex].questionJson[action.payload.sectionName].questions,
                  {
                    ...action.payload.questionDetails
                  }
                ]
              }
            },
          },
          ...state.reportData.slice(action.payload.selectedIndex + 1)
        ]
      }
    case actionConstants.UPDATE_QUESTION_JSON:
      return {
        ...state,
        reportData: [
          ...state.reportData.slice(0, action.payload.selectedIndex),
          {
            ...state.reportData[action.payload.selectedIndex],
            questionJson: {
              ...state.reportData[action.payload.selectedIndex].questionJson,
              [action.payload.sectionName]: {
                ...state.reportData[action.payload.selectedIndex].questionJson[action.payload.sectionName],
                questions: [...action.payload.questionJson]
              }
            },
          },
          ...state.reportData.slice(action.payload.selectedIndex + 1)
        ]
      }
    case actionConstants.SET_CONTRIL_PANEL_ANSWERS:
      let oldPanelDetails = state.answerData[action.payload.selectedIndex][action.payload.sectionName] ? state.answerData[action.payload.selectedIndex][action.payload.sectionName] : []
      let particularIndexDetail = oldPanelDetails[action.payload.answerIndex] ? oldPanelDetails[action.payload.answerIndex] : { _id: moment().valueOf() }
      const _timeStamp = {};
      if (action.payload.timeStamp) {
        _timeStamp.timeStamp = action.payload.timeStamp;
      }
      let newAnswer = {
        [action.payload.key]: {
          ...particularIndexDetail[action.payload.key],
          ans: action.payload.value,
          isMemorized: action.payload.isMemorized,
          ..._timeStamp,
          // type: action.payload.type,
        }
      };
      if (action.payload.hasOwnProperty('isOther')) {
        newAnswer[action.payload.key].isOther = action.payload.isOther;
      };
      if (action.payload.extras) {
        newAnswer = {
          ...action.payload.extras,
        };
      }
      if (action.payload.qrCode) {
        return {
          ...state,
          answerData: [
            ...state.answerData.slice(0, action.payload.selectedIndex),
            {
              ...state.answerData[action.payload.selectedIndex],
              [action.payload.sectionName]: [
                ...oldPanelDetails.slice(0, action.payload.answerIndex),
                {
                  ...particularIndexDetail,
                  controlPanelPanelQRCode: {
                    ans: action.payload.qrCode
                  },
                  ...newAnswer,
                },
                ...oldPanelDetails.slice(action.payload.answerIndex + 1)
              ]
            },
            ...state.answerData.slice(action.payload.selectedIndex + 1)
          ]
        }
      }
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: [
              ...oldPanelDetails.slice(0, action.payload.answerIndex),
              {
                ...particularIndexDetail,
                ...newAnswer,
              },
              ...oldPanelDetails.slice(action.payload.answerIndex + 1)
            ]
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ]
      }


    case actionConstants.UNSET_CONTROL_PANEL_ANSWERS:
      let oldPanelDetail = state.answerData[action.payload.selectedIndex][action.payload.sectionName] ? state.answerData[action.payload.selectedIndex][action.payload.sectionName] : []
      let particularIndexDetails = oldPanelDetail[action.payload.answerIndex] ? oldPanelDetail[action.payload.answerIndex] : {}
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: [
              ...oldPanelDetail.slice(0, action.payload.answerIndex),
              {
                ...particularIndexDetails,
                [action.payload.key]: undefined
              },
              ...oldPanelDetail.slice(action.payload.answerIndex + 1)
            ]
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ]
      }
    case actionConstants.ADD_SYSTEM_QR:
      let copyState = JSON.parse(JSON.stringify(state));
      copyState.answerData[action.payload.selectedIndex][action.payload.menuOption || 'controlPanel'].push(action.payload.answers)
      return {
        ...copyState
      }
    case actionConstants.ADD_DEVICES_QR:
      let copy = JSON.parse(JSON.stringify(state));
      copy.answerData[action.payload.selectedIndex][action.payload.sectionName || 'device'].push(action.payload.answers)
      return {
        ...copy
      }
    case actionConstants.SET_OFF_SWITCHES_CONTROL_PANEL:
      let oldPanel = state.answerData[action.payload.selectedIndex][action.payload.sectionName] ? state.answerData[action.payload.selectedIndex][action.payload.sectionName] : []
      let particularIndexDetailing = oldPanel[action.payload.answerIndex] ? oldPanel[action.payload.answerIndex] : {}
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: [
              ...oldPanel.slice(0, action.payload.answerIndex),
              {
                ...particularIndexDetailing,
                [action.payload.key]: {
                  ...particularIndexDetailing[action.payload.key],
                  [action.payload.id]: action.payload.value,
                  isMemorized: !!action.payload.isMemorized
                }
              },
              ...oldPanel.slice(action.payload.answerIndex + 1)
            ]
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ]
      }
    case actionConstants.DELETE_DEVICE_HARDCODED_REPORT:
      const oldDevicesDetail = state.answerData[action.payload.selectedIndex][action.payload.sectionName] ?
        state.answerData[action.payload.selectedIndex][action.payload.sectionName] : []
      return {
        ...state,
        isSync: action.isSync,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          {
            ...state.answerData[action.payload.selectedIndex],
            [action.payload.sectionName]: [
              ...oldDevicesDetail.slice(0, action.payload.deleteDeviceIndex),
              {
                ...oldDevicesDetail[action.payload.deleteDeviceIndex],
                isDeleted: true
              },
              ...oldDevicesDetail.slice(action.payload.deleteDeviceIndex + 1)
            ]
          },
          ...state.answerData.slice(action.payload.selectedIndex + 1)
        ]
      }

    case actionConstants.ADD_CUSTOM_QUESTION_CONTROL_PANEL:
      if (action.payload.isNotSubQuestion) {
        newQuestions = [
          ...state.reportData[action.payload.selectedIndex].questionJson[action.payload.sectionName].questions,
          {
            ...action.payload.questionDetails
          }
        ];
      } else {
        newQuestions = [{
          ...state.reportData[action.payload.selectedIndex].questionJson[action.payload.sectionName].questions[0],
          children: [
            ...state.reportData[action.payload.selectedIndex].questionJson[action.payload.sectionName].questions[0].children,
            {
              ...action.payload.questionDetails
            }
          ]
        }]
      }
      return {
        ...state,
        reportData: [
          ...state.reportData.slice(0, action.payload.selectedIndex),
          {
            ...state.reportData[action.payload.selectedIndex],
            questionJson: {
              ...state.reportData[action.payload.selectedIndex].questionJson,
              [action.payload.sectionName]: {
                ...state.reportData[action.payload.selectedIndex].questionJson[action.payload.sectionName],
                questions: [
                  ...newQuestions,
                ]
              }
            },
          },
          ...state.reportData.slice(action.payload.selectedIndex + 1)
        ]
      }

    case actionConstants.UPDATE_CUSTOM_QUESTION_CONTROL_PANEL:
      return {
        ...state,
        reportData: [
          ...state.reportData.slice(0, action.payload.selectedIndex),
          {
            ...state.reportData[action.payload.selectedIndex],
            questionJson: {
              ...state.reportData[action.payload.selectedIndex].questionJson,
              [action.payload.sectionName]: {
                ...state.reportData[action.payload.selectedIndex].questionJson[action.payload.sectionName],
                questions: [{
                  ...state.reportData[action.payload.selectedIndex].questionJson[action.payload.sectionName].questions[0],
                  children: [
                    ...action.payload.questionJson
                  ]
                }
                ]
              }
            },
          },
          ...state.reportData.slice(action.payload.selectedIndex + 1)
        ]
      }

    case actionConstants.ADD_PARTICULAR_ID_QUESTION:
      return {
        ...state,
        reportData: [
          ...state.reportData.slice(0, action.payload.selectedIndex),
          {
            ...state.reportData[action.payload.selectedIndex],
            questionJson: {
              ...state.reportData[action.payload.selectedIndex].questionJson,
              [action.payload.sectionName]: {
                ...state.reportData[action.payload.selectedIndex].questionJson[action.payload.sectionName],
                questions: [
                  ...state.reportData[action.payload.selectedIndex].questionJson[action.payload.sectionName].questions.slice(0, action.payload.idIndex),
                  {
                    ...action.payload.particularQuestionDetail
                  },
                  ...state.reportData[action.payload.selectedIndex].questionJson[action.payload.sectionName].questions.slice(action.payload.idIndex + 1),

                ]
              }
            },
          },
          ...state.reportData.slice(action.payload.selectedIndex + 1)
        ]
      }

    case actionConstants.GET_HARDCODED_REPORT_CLIENT_DETAILS_REQUEST:
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          isFetching: true
        }
      }

    case actionConstants.GET_HARDCODED_REPORT_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          isFetching: false,
          isSuccess: true,
          clientData: action.payload
        }
      }

    case actionConstants.GET_HARDCODED_REPORT_CLIENT_DETAILS_FAILURE:
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          isFetching: false,
          isError: true,
        }
      }

    case actionConstants.GET_HARDCODED_REPORT_CLIENT_DETAILS_CLEAR:
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          isSuccess: false,
          isFetching: false,
          isError: false
        }
      };
    case actionConstants.RESET_UPDATE_INSPECTION_ARRAY_INITIAL_STATE:
      return {
        ...state,
        ...initialState
      };
    case actionConstants.CANCEL_PARTICULAR_HARDCODED_REPORT:
      return {
        ...state,
        answerData: [
          ...state.answerData.slice(0, action.payload.selectedIndex),
          ...state.answerData.slice(action.payload.selectedIndex + 1),
        ],
        reportData: [
          ...state.reportData.slice(0, action.payload.selectedIndex),
          ...state.reportData.slice(action.payload.selectedIndex + 1),
        ]
      }

    default:
      return state;
  }
};
