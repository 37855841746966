import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import OfflineEnhancedLayout from '../../layouts/OfflineEnhancedLayout';
import toggleOffline, {
  toggleModalVisibility,
  selectOfflineModalOption,
  setCustomerModalState,
  toggleCustomerModalVisibility,
  addCustomerToList,
  toggleJobsModalVisibility,
  setSelectedJobsClients,
  resetSelectedJobsClients,
  resetOfflineJobsList,
  resetOfflineReducer,
  resetOfflineFetchFlags,
  getCustomerList,
  getJobList,
  getTeamList,
  getReportTemplateList,
  getDeviceList,
  getInnerDeviceList,
  setStep,
  setClientIds,
  setDeviceIds,
  deleteJob,
  resetCustomerList,
  resetJobList,
  resetTeamList,
  resetReportTemplateList,
  resetDeviceList,
  resetInnerDeviceList,
  getPartList,
  resetPartList,
  getReportListByJobIds,
  resetReportListByJobIds,
  getReportListByCustomer,
  resetReportListByCustomer,
  deleteReportByCustomer,
  deleteReportByJobId,
  createReportEntries,
  fillJobDetails,
  addPart,
  deletePart,
  addLabour,
  deleteLabour,
  setReportSelectedToUpload,
  deleteReportEntry,
  setUpdatedAnswersReportData,
  uploadSignatures,
  setSignatureType,
  uploadSignaturesReset,
  getQuestionJsonDefaultReports,
  resetQuestionJsonDefaultReports,
  createReportEntryForNewReport,
} from '../../redux/actions/offlineEnhanced';
import {
  OfflineModeContext,
  onlineToastMessage,
  fetch,
  offlineModeOptions,
} from '../../libs/constants/offlineEnhanced';
import getClientsList from '../../redux/actions/getClientList';
import { reportCat } from '../../redux/actions/reportCategory';
import getJobListAction, {
  getMissedJobListAction,
} from '../../redux/actions/jobLinkActions';
import {
  clearInspectionState,
  setAnswer,
  clearAnswers,
} from '../../redux/actions/updateAnswer';
import formReportDetails from '../../redux/actions/addReportCat';
import {
  updateReport,
  updateReportClear,
  createInspection,
} from '../../redux/actions/reportUpdate';
import getAccessPermissions from '../../redux/actions/getAccessPermissions';
import { displayToast } from '../../_helpers/commonFunctions';
import {
  getJobIds,
  deleteReportDetailsFromDB,
} from '../../_helpers/offlineEnhanced';

const LIMIT = 100;

class OfflineEnhanced extends Component {
  setNextStep = () => {
    const { offlineMode } = this.props;

    if (offlineMode.step === fetch.CUSTOMER_LIST) {
      this.props.setStep(fetch.REPORT_LIST_BY_JOB_ID);
    } else if (
      offlineMode.step === fetch.REPORT_LIST_BY_JOB_ID ||
      offlineMode.step === fetch.REPORT_LIST_BY_CUSTOMER
    ) {
      this.props.setStep(fetch.TEAM_LIST);
    } else if (offlineMode.step === fetch.JOB_LIST) {
      if (offlineMode.selectedOfflineModeOption === offlineModeOptions[1]) {
        this.props.setStep(fetch.CUSTOMER_LIST);
      } else if (
        offlineMode.selectedOfflineModeOption === offlineModeOptions[0]
      ) {
        this.props.setStep(fetch.TEAM_LIST);
      } else {
        this.props.setStep(fetch.REPORT_LIST_BY_CUSTOMER);
      }
    } else if (
      offlineMode.selectedOfflineModeOption === offlineModeOptions[0] &&
      offlineMode.step === fetch.QUESTION_JSON_DEFAULT_EPORT
    ) {
      this.props.setStep(offlineMode.step + 2);
    } else {
      this.props.setStep(offlineMode.step + 1);
    }
  };

  handlePaginatedRequests = (
    reducerName,
    action,
    prevProps,
    resetReducerAction,
    postPaginatedRequest
  ) => {
    const { offlineMode } = this.props;
    const { offlineMode: prevOfflineMode } = prevProps;
    const { page, pages, isSuccess, isFetching, isFirstRequest } =
      offlineMode[reducerName];
    if (offlineMode.isErrorWhileDownloadingData) {
      this.props.resetOfflineReducer();
      return;
    }
    if (!isFetching && isFirstRequest) {
      action();
    } else if (
      !isFetching &&
      isSuccess === true &&
      isSuccess !== prevOfflineMode[reducerName].isSuccess
    ) {
      resetReducerAction();
      if (page < pages) {
        action();
      } else if (page === pages || (pages === 0 && page === 1)) {
        if (postPaginatedRequest) {
          postPaginatedRequest();
        } else {
          this.setNextStep();
        }
      }
    }
  };

  handleDownloadData = (prevProps) => {
    const {
      step,
      customerListFetchStatus,
      jobsListFetchStatus,
      teamList,
      reportTemplateList,
      deviceList,
      innerDeviceList,
      partList,
      jobsList,
      customerModalState,
      clientIds,
      deviceIds,
      selectedOfflineModeOption,
      reportListByCustomer,
      customersList,
    } = this.props.offlineMode;

    switch (step) {
      case fetch.CUSTOMER_LIST:
        this.handlePaginatedRequests(
          'customerListFetchStatus',
          () =>
            this.props.getCustomerList(
              customerListFetchStatus.page + 1,
              LIMIT,
              getJobIds(jobsList)
            ),
          prevProps,
          this.props.resetCustomerList
        );
        break;
      case fetch.JOB_LIST:
        this.handlePaginatedRequests(
          'jobsListFetchStatus',
          () =>
            this.props.getJobList(jobsListFetchStatus.page + 1, LIMIT, {
              clientId: customerModalState.selectedCustomer._id,
              reportCategory: customerModalState.reportCategory,
              type: customerModalState.reportType,
              startDate: moment().startOf('day').valueOf(),
              endDate: moment().endOf('day').valueOf(),
              jobStatus:
                selectedOfflineModeOption === offlineModeOptions[1]
                  ? ['Assigned']
                  : ['Assigned', 'Inprogress'],
              onlySelf: selectedOfflineModeOption === offlineModeOptions[1],
            }),
          prevProps,
          this.props.resetJobList
        );
        break;
      case fetch.REPORT_LIST_BY_JOB_ID:
        this.handlePaginatedRequests(
          'reportListByJobIds',
          () => this.props.getReportListByJobIds(Object.keys(jobsList)),
          prevProps,
          this.props.resetReportListByJobIds
        );
        break;
      case fetch.REPORT_LIST_BY_CUSTOMER:
        this.handlePaginatedRequests(
          'reportListByCustomer',
          () =>
            this.props.getReportListByCustomer(
              reportListByCustomer.page + 1,
              LIMIT,
              '',
              customerModalState.reportType,
              customerModalState.reportCategory,
              1,
              '',
              '',
              [
                'Created',
                'Inprogress',
                'Approval Required',
                'Rejected',
                'Deficiency',
              ],
              customerModalState.selectedCustomer._id
            ),
          prevProps,
          this.props.resetReportListByCustomer
        );
        break;
      case fetch.TEAM_LIST:
        this.handlePaginatedRequests(
          'teamList',
          () => this.props.getTeamList(teamList.page + 1, LIMIT),
          prevProps,
          this.props.resetTeamList
        );
        break;
      case fetch.REPORT_TEMPLATE_LIST:
        this.handlePaginatedRequests(
          'reportTemplateList',
          () =>
            this.props.getReportTemplateList(
              reportTemplateList.page + 1,
              LIMIT
            ),
          prevProps,
          this.props.resetReportTemplateList
        );
        break;
      case fetch.DEVICE_LIST:
        this.handlePaginatedRequests(
          'deviceList',
          () =>
            this.props.getDeviceList(deviceList.page + 1, LIMIT, clientIds[0]),
          prevProps,
          this.props.resetDeviceList,
          clientIds.length > 1
            ? () => {
                this.props.getDeviceList(1, LIMIT, clientIds[1]);
                this.props.setClientIds();
              }
            : () => {
                this.props.setClientIds();
                this.setNextStep();
              }
        );
        break;
      case fetch.INNER_DEVICE_LIST:
        this.handlePaginatedRequests(
          'innerDeviceList',
          () =>
            deviceIds[0]
              ? this.props.getInnerDeviceList(
                  innerDeviceList.page + 1,
                  LIMIT,
                  deviceIds[0]
                )
              : this.setNextStep(),
          prevProps,
          this.props.resetInnerDeviceList,
          deviceIds.length > 1
            ? () => {
                this.props.getInnerDeviceList(1, LIMIT, deviceIds[1]);
                this.props.setDeviceIds();
              }
            : () => {
                this.props.setDeviceIds();
                this.setNextStep();
              }
        );
        break;
      case fetch.PART_LIST:
        this.handlePaginatedRequests(
          'partList',
          () => this.props.getPartList(partList.page + 1, LIMIT),
          prevProps,
          this.props.resetPartList
        );
        break;
      case fetch.QUESTION_JSON_DEFAULT_EPORT:
        const { selectedOfflineModeOption } = this.props.offlineMode;
        const customersListIds = Object.keys(customersList);
        const createdFor = customerModalState.selectedCustomer._id || '';
        const payload = {
          mode: selectedOfflineModeOption,
          reportCategory: [],
          createdFor,
          customersList: customersListIds,
        };
        this.handlePaginatedRequests(
          'questionJsonDefaultReports',
          () => this.props.getQuestionJsonDefaultReports(payload),
          prevProps,
          this.props.resetQuestionJsonDefaultReports
        );
        break;
      case fetch.CREATE_REPORT_ENTRIES:
        this.props.createReportEntries();
        this.setNextStep();
        break;
      case fetch.CREATE_NEW_REPORT:
        if (
          this.props.offlineMode.isOffline &&
          this.props.offlineMode.selectedOfflineModeOption ===
            offlineModeOptions[0]
        ) {
          this.createNewReport();
          this.setNextStep();
        }
        break;
      default:
        break;
    }
  };

  createNewReport = () => {
    const { offlineMode } = this.props;
    const { customerModalState, currentNewReportIndex } = offlineMode;
    const { questionJsonDefaultReports } = offlineMode.questionJsonDefaultReports;
    const reportEntry = currentNewReportIndex + 1;
    const { reportCategory} = customerModalState;
    const formData = {
      reportCategory,
      createdFor:
        customerModalState &&
        customerModalState.selectedCustomer &&
        customerModalState.selectedCustomer._id,
      createdForName:
        customerModalState &&
        customerModalState.selectedCustomer &&
        customerModalState.selectedCustomer.businessName,
      reportType: customerModalState.reportType,
    };
    if (customerModalState.reportType === 'Inspection') {
      const questionJson = questionJsonDefaultReports[reportCategory];
      const { reportData, answers } = questionJson?.[0] || {};
      formData.missedItems = {};
      formData.answers = answers || {};
      formData.templateType = 'Static';
      formData.questionJson = reportData?.questionJson;
      formData.frequency = null;
    }
    if (customerModalState.reportType !== 'Inspection') {
      formData.jobDetails = {
        terms: 'Fixed Price',
      };
      formData.labours = [];
      formData.parts = [];
    }
    this.props.formReportDetails({
      ...formData,
      customerValue:
        customerModalState &&
        customerModalState.selectedCustomer &&
        customerModalState.selectedCustomer._id,
      catValue: customerModalState.reportCategory,
      typeValue: customerModalState.reportType,
    });
    this.props.createReportEntryForNewReport(formData);
    this.props.setUpdatedAnswersReportData({
      ...formData,
      _id: reportEntry,
    });
  };

  componentDidUpdate(prevProps) {
    const { reportUpdate, offlineMode } = this.props;
    const {
      isOffline,
      jobsList,
      reportListByCustomer,
      reportListByJobIds,
      reportSelectedToUpload,
      isErrorWhileDownloadingData,
      downloadErrorMessage,
    } = offlineMode;
    if (!isOffline && prevProps.offlineMode.isOffline !== isOffline) {
      this.props.getAccessPermissions();
    }
    if (
      !isOffline &&
      prevProps.offlineMode.isOffline !== isOffline &&
      (!isEmpty(jobsList) ||
        !isEmpty(reportListByCustomer.reportListByCustomer) ||
        !isEmpty(reportListByJobIds.reportListByJobIds))
    ) {
      displayToast(onlineToastMessage, 'success');
    }
    if (
      isErrorWhileDownloadingData &&
      prevProps.offlineMode.isErrorWhileDownloadingData !==
        isErrorWhileDownloadingData
    ) {
      this.props.resetOfflineReducer();
      displayToast(downloadErrorMessage, 'error');
    }
    if (
      !isEqual(prevProps.offlineMode, this.props.offlineMode) &&
      !isErrorWhileDownloadingData
    ) {
      this.handleDownloadData(prevProps);
    }
    if (
      reportUpdate.isSuccess &&
      prevProps.reportUpdate.isSuccess !== reportUpdate.isSuccess
    ) {
      if (
        Object.prototype.hasOwnProperty.call(jobsList, reportSelectedToUpload)
      ) {
        this.props.deleteJob(reportSelectedToUpload);
      }
      if (
        Object.prototype.hasOwnProperty.call(
          reportListByCustomer.reportListByCustomer,
          reportSelectedToUpload
        )
      ) {
        this.props.deleteReportByCustomer(reportSelectedToUpload);
      }
      const selectedReportByCustomer = Object.values(
        reportListByCustomer.reportListByCustomer
      ).filter((key) => key.jobId === reportSelectedToUpload);
      if (selectedReportByCustomer[0] && selectedReportByCustomer[0]._id) {
        this.props.deleteReportByCustomer(selectedReportByCustomer[0]._id);
      }
      if (
        Object.prototype.hasOwnProperty.call(
          reportListByJobIds.reportListByJobIds,
          reportSelectedToUpload
        )
      ) {
        this.props.deleteReportByJobId(reportSelectedToUpload);
      }
      displayToast(reportUpdate.reportMessage, 'success');
      deleteReportDetailsFromDB(reportSelectedToUpload);
      this.props.deleteReportEntry(reportSelectedToUpload);
      this.props.setReportSelectedToUpload(null);
      this.props.updateReportClear();
    } else if (
      reportUpdate.error &&
      prevProps.reportUpdate.error !== reportUpdate.error
    ) {
      displayToast(reportUpdate.error, 'error');
      this.props.setReportSelectedToUpload(null);
      this.props.updateReportClear();
    }
  }

  componentDidMount() {
    this.props.resetOfflineFetchFlags();
    this.props.getAccessPermissions();
  }

  render() {
    return (
      <OfflineModeContext.Provider value={{ ...this.props }}>
        <OfflineEnhancedLayout />
      </OfflineModeContext.Provider>
    );
  }
}

const mapStateToProps = ({
  offlineMode,
  getClientList,
  reportCategory,
  jobListDetails,
  reportUpdate,
  auth,
}) => ({
  offlineMode,
  clientData: getClientList,
  reportCategory,
  jobListDetails,
  reportUpdate,
  employeeDetails: auth.login.user,
});

OfflineEnhanced.propTypes = {
  toggleOffline: PropTypes.func,
  toggleModalVisibility: PropTypes.func,
  offlineMode: PropTypes.object,
  selectOfflineModalOption: PropTypes.func,
  setCustomerModalState: PropTypes.func,
  toggleCustomerModalVisibility: PropTypes.func,
  getClientsList: PropTypes.func,
  clientData: PropTypes.object,
  reportCat: PropTypes.func,
  reportCategory: PropTypes.object,
  addCustomerToList: PropTypes.func,
  toggleJobsModalVisibility: PropTypes.func,
  jobListDetails: PropTypes.object,
  setSelectedJobsClients: PropTypes.func,
  resetSelectedJobsClients: PropTypes.func,
  resetOfflineJobsList: PropTypes.func,
  resetOfflineReducer: PropTypes.func,
  resetOfflineFetchFlags: PropTypes.func,
  getCustomerList: PropTypes.func,
  getJobList: PropTypes.func,
  getTeamList: PropTypes.func,
  getReportTemplateList: PropTypes.func,
  getDeviceList: PropTypes.func,
  getInnerDeviceList: PropTypes.func,
  setStep: PropTypes.func,
  setClientIds: PropTypes.func,
  setDeviceIds: PropTypes.func,
  deleteJob: PropTypes.func,
  resetCustomerList: PropTypes.func,
  resetJobList: PropTypes.func,
  resetTeamList: PropTypes.func,
  resetReportTemplateList: PropTypes.func,
  resetDeviceList: PropTypes.func,
  resetInnerDeviceList: PropTypes.func,
  resetPartList: PropTypes.func,
  getPartList: PropTypes.func,
  getReportListByJobIds: PropTypes.func,
  resetReportListByJobIds: PropTypes.func,
  getReportListByCustomer: PropTypes.func,
  resetReportListByCustomer: PropTypes.func,
  deleteReportByCustomer: PropTypes.func,
  deleteReportByJobId: PropTypes.func,
  createReportEntries: PropTypes.func,
  fillJobDetails: PropTypes.func,
  addPart: PropTypes.func,
  deletePart: PropTypes.func,
  addLabour: PropTypes.func,
  deleteLabour: PropTypes.func,
  clearInspectionState: PropTypes.func,
  formReportDetails: PropTypes.func,
  updateReport: PropTypes.func,
  setReportSelectedToUpload: PropTypes.func,
  reportUpdate: PropTypes.object,
  deleteReportEntry: PropTypes.func,
  updateReportClear: PropTypes.func,
  clearAnswers: PropTypes.func,
  setAnswer: PropTypes.func,
  setUpdatedAnswersReportData: PropTypes.func,
  getAccessPermissions: PropTypes.func,
  uploadSignatures: PropTypes.func,
  setSignatureType: PropTypes.func,
  uploadSignaturesReset: PropTypes.func,
  getQuestionJsonDefaultReports: PropTypes.func,
  resetQuestionJsonDefaultReports: PropTypes.func,
  createInspection: PropTypes.func,
  createReportEntryForNewReport: PropTypes.func,
};

export default connect(mapStateToProps, {
  toggleOffline,
  toggleModalVisibility,
  selectOfflineModalOption,
  setCustomerModalState,
  toggleCustomerModalVisibility,
  getClientsList,
  reportCat,
  addCustomerToList,
  toggleJobsModalVisibility,
  getJobListAction,
  getMissedJobListAction,
  setSelectedJobsClients,
  resetSelectedJobsClients,
  resetOfflineJobsList,
  resetOfflineReducer,
  resetOfflineFetchFlags,
  getCustomerList,
  getJobList,
  getTeamList,
  getReportTemplateList,
  getDeviceList,
  getInnerDeviceList,
  setStep,
  setClientIds,
  setDeviceIds,
  deleteJob,
  resetCustomerList,
  resetJobList,
  resetTeamList,
  resetReportTemplateList,
  resetDeviceList,
  resetInnerDeviceList,
  resetPartList,
  getPartList,
  getReportListByJobIds,
  resetReportListByJobIds,
  getReportListByCustomer,
  resetReportListByCustomer,
  deleteReportByCustomer,
  deleteReportByJobId,
  createReportEntries,
  fillJobDetails,
  addPart,
  deletePart,
  addLabour,
  deleteLabour,
  clearInspectionState,
  formReportDetails,
  updateReport,
  setReportSelectedToUpload,
  deleteReportEntry,
  updateReportClear,
  clearAnswers,
  setAnswer,
  setUpdatedAnswersReportData,
  getAccessPermissions,
  uploadSignatures,
  setSignatureType,
  uploadSignaturesReset,
  getQuestionJsonDefaultReports,
  resetQuestionJsonDefaultReports,
  createInspection,
  createReportEntryForNewReport,
})(OfflineEnhanced);
