import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Table from '../../components/ReusableTable';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import Modal from '../../components/Modal';
import {
  REPORT_FILTER_LIST_HEADER_FOR_EXTINGUISHER,
  reportFilters,
} from '../../utils/Constants';
import {
  tableHeader,
  tableHeaderStartDate,
  tableHeaderServicesDue,
  tableHeaderServicesDone,
} from './ReportFilterListTableHeader';
import ReportFilterListTableBody from './ReportFilterListTableBody';
import ReportFilterListHeader from './ReportFilterListHeader';
import ProfileModalBody from './ProfileModalBody';
import ServiceDueTableRow from './ServiceDueTableRow';
import Recipients from './Recipients';

/**
 * returns the string which has to shown on listing
 * @param {*} filterName - selected filter
 */
const getEndMessage = (filterName) => {
  let endMessage = `No ${filterName} completed`;
  if (filterName === REPORT_FILTER_LIST_HEADER_FOR_EXTINGUISHER[0]) {
    endMessage = 'No reports completed';
  } else if (
    filterName === REPORT_FILTER_LIST_HEADER_FOR_EXTINGUISHER[4] ||
    filterName === REPORT_FILTER_LIST_HEADER_FOR_EXTINGUISHER[3]
  ) {
    endMessage = `No ${filterName}`;
  }
  return endMessage;
};

/**
 * return table heading
 * @param {*} filterName - selected filter name
 */
const getTableHeading = (filterName) => {
  if (filterName === reportFilters.serviceDue) {
    return tableHeaderServicesDue;
  }
  if (filterName === reportFilters.serviceDone) {
    return tableHeaderServicesDone;
  }
  if (filterName === reportFilters.incompleteReport) {
    return tableHeaderStartDate;
  }
  return tableHeader;
};

/**
 * Shows the header and table basis of filtering.
 * @param {*} props
 */
// eslint-disable-next-line react/display-name
const ReportFilterList = forwardRef((props, ref) => {
  const [isRecipientsModal, toggleRecipientsModalVisibility] = useState(false);
  const [reportId, setReportId] = useState(null);
  const {
    showFilterList, // Method called on selecting filter.
    selectedFilterName, // Contains selected filter name.
    handleClickSave,
    hasMoreItems, // Condition to check tabale has more items to display or not.
    isFetchingList,
    isDeleteModalVisible,
    isShowProfile,
    handleDelete, // Method called on clicking delete icon.
    handleConfirmDelete,
    handleShowProfile,
    tableBodyData,
    downloadReportPDF,
    clearReportPdf,
    profileDetails,
    handleEditReport,
    loadMore,
    servicesDueDetails,
    emailReportPDF,
    clientDetails,
    SetIsPromptAction,
    isReportNeeded,
  } = props;
  let modalHeader = reportFilters.inspectionMessage;
  if (selectedFilterName === reportFilters.workOrder) {
    modalHeader = reportFilters.workorderMessage;
  }
  if (selectedFilterName === reportFilters.quote) {
    modalHeader = reportFilters.quoteMessage;
  }
  if (selectedFilterName === reportFilters.serviceDue
    || selectedFilterName === reportFilters.serviceDone
  ) {
    modalHeader = reportFilters.serviceMessage;
  }

  useImperativeHandle(ref, () => ({
    toggleRecipientsModalVisibility,
    setReportId,
  }));

  return (
    <React.Fragment>
      {isDeleteModalVisible && (
        <DeleteConfirmationModal
          handleClickCancel={handleDelete}
          handleClickCross={handleDelete}
          handleClickDelete={handleConfirmDelete}
        />
      )}
      {isShowProfile && (
        <Modal
          modalHeading={modalHeader}
          ModalBody={ProfileModalBody}
          handleClickCross={handleShowProfile}
          ModalBodyProps={{
            profileDetails,
            handleEditReport,
          }}
        />
      )}
      {isRecipientsModal && (
        <Modal
          modalHeading={"Recipients' Emails"}
          modalId="recipients-modal"
          ModalBody={Recipients}
          handleClickCross={() => {
            setReportId(null);
            toggleRecipientsModalVisibility(false);
          }}
          ModalBodyProps={{
            emailReportPDF,
            reportId,
            clientDetails,
            SetIsPromptAction,
          }}
        />
      )}
      <ReportFilterListHeader
        showFilterList={showFilterList}
        selectedFilterName={selectedFilterName}
        handleClickSave={handleClickSave}
        exportList={loadMore}
        servicesDueDetails={servicesDueDetails}
        isReportNeeded={isReportNeeded}
      />
      <Table
        headerData={getTableHeading(selectedFilterName)}
        mainTableClass="admin-table"
        mainTableSubClass=""
        contentBoxClass=""
        RowToRender={
          selectedFilterName === reportFilters.serviceDue
          || selectedFilterName === reportFilters.serviceDone
            ? ServiceDueTableRow
            : ReportFilterListTableBody
        }
        RowToRenderProps={{
          handleDelete,
          handleShowProfile,
          downloadReportPDF,
          clearReportPdf,
          handleEditReport,
          isReportNeeded,
          emailReportPDF: (_reportId) => {
            setReportId(_reportId);
            toggleRecipientsModalVisibility(true);
          },
          isServiceDone: selectedFilterName === reportFilters.serviceDone,
        }}
        bodyData={tableBodyData}
        hasMoreItems={hasMoreItems}
        isLoaderVisible={isFetchingList}
        isEndMessageVisible={!isFetchingList && !tableBodyData.length}
        endMessage={getEndMessage(selectedFilterName)}
        loadMore={loadMore}
      />
    </React.Fragment>
  );
});

ReportFilterList.propTypes = {
  showFilterList: PropTypes.func,
  handleDelete: PropTypes.func,
  handleShowProfile: PropTypes.func,
  handleConfirmDelete: PropTypes.func,
  selectedFilterName: PropTypes.string,
  hasMoreItems: PropTypes.bool,
  isFetchingList: PropTypes.bool,
  isShowProfile: PropTypes.bool,
  isDeleteModalVisible: PropTypes.bool,
  tableBodyData: PropTypes.array,
  handleClickSave: PropTypes.func,
  downloadReportPDF: PropTypes.func,
  clearReportPdf: PropTypes.func,
  profileDetails: PropTypes.array,
  handleEditReport: PropTypes.func,
  servicesDueDetails: PropTypes.object,
  loadMore: PropTypes.func,
  emailReportPDF: PropTypes.func,
  clientDetails: PropTypes.object,
  SetIsPromptAction: PropTypes.func,
  isReportNeeded: PropTypes.any,
};

export default ReportFilterList;
