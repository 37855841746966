import actionConstants from '../constants/ActionConstants';
import ActionDispatcher from '../../api/ActionDispatcher';
import {
  clientDeviceList,
  clientInnerDeviceList,
  reportsListApiCall,
} from '../../api/apiCallsCustomer';
import { labourListDropDown } from '../../api/apiCallsLabour';
import { reportTemplateListApiCall } from '../../api/apiCallReport';
import jobListApiCall from '../../api/apiCallsJobLink';
import { partListDropDown } from '../../api/apiCallsParts';
import downloadClientList, {
  reportListByJobIdsApiCall,
  questionJsonDefaultReports,
} from '../../api/apiCallsOfflineEnhanced';
import { ReportsFileUpload } from '../../api/reports';

export default (isOffline) => (dispatch) =>
  dispatch({
    type: actionConstants.TOGGLE_OFFLINE_MODE,
    payload: { isOffline },
  });

export const toggleModalVisibility = (isModalVisible) => (dispatch) =>
  dispatch({
    type: actionConstants.TOGGLE_OFFLINE_MODE_MODAL_VISIBILITY,
    payload: { isModalVisible },
  });

export const toggleCustomerModalVisibility =
  (isCustomerModalVisible) => (dispatch) =>
    dispatch({
      type: actionConstants.TOGGLE_CUSTOMER_MODAL_VISIBILITY,
      payload: { isCustomerModalVisible },
    });

export const toggleJobsModalVisibility =
  (isSelectJobsModalVisible) => (dispatch) =>
    dispatch({
      type: actionConstants.TOGGLE_JOBS_MODAL_VISIBILITY,
      payload: { isSelectJobsModalVisible },
    });

export const selectOfflineModalOption = (selectedOfflineMode) => (dispatch) =>
  dispatch({
    type: actionConstants.SELECT_OFFLINE_MODE_OPTION,
    payload: { selectedOfflineMode },
  });

export const setCustomerModalState = (customerData) => (dispatch) =>
  dispatch({
    type: actionConstants.SET_CUSTOMER_MODAL_STATE,
    payload: { ...customerData },
  });

export const addCustomerToList = () => (dispatch) =>
  dispatch({
    type: actionConstants.ADD_CUSTOMER_TO_LIST,
  });

export const resetOfflineCustomersList = () => (dispatch) =>
  dispatch({
    type: actionConstants.RESET_OFFLINE_CUSTOMERS_LIST,
  });

export const setSelectedJobsClients = (jobDetails, isAddJob) => (dispatch) =>
  dispatch({
    type: actionConstants.SET_SELECTED_JOBS_CLIENTS,
    payload: { jobDetails, isAddJob },
  });

export const resetSelectedJobsClients = () => (dispatch) =>
  dispatch({
    type: actionConstants.RESET_SELECTED_JOBS_CLIENTS,
  });

export const resetOfflineJobsList = () => (dispatch) =>
  dispatch({
    type: actionConstants.RESET_OFFLINE_JOBS_LIST,
  });

export const resetOfflineReducer = () => (dispatch) =>
  dispatch({
    type: actionConstants.RESET_OFFLINE_DATA,
  });
export const resetOfflineFetchFlags = () => (dispatch) =>
  dispatch({
    type: actionConstants.RESET_OFFLINE_FETCH_FLAGS,
  });

export const setStep = (step) => (dispatch) =>
  dispatch({
    type: actionConstants.SET_STEP,
    payload: { step },
  });

export const setClientIds = () => (dispatch) =>
  dispatch({
    type: actionConstants.SET_CLIENT_IDS,
  });

export const setDeviceIds = () => (dispatch) =>
  dispatch({
    type: actionConstants.SET_DEVICE_IDS,
  });

export const deleteJob = (jobId) => (dispatch) =>
  dispatch({
    type: actionConstants.DELETE_JOB,
    payload: { jobId },
  });

export const deleteReportByCustomer = (reportId) => (dispatch) =>
  dispatch({
    type: actionConstants.DELETE_REPORT_BY_CUSTOMER,
    payload: { reportId },
  });

export const deleteReportByJobId = (reportId) => (dispatch) =>
  dispatch({
    type: actionConstants.DELETE_REPORT_BY_JOB_ID,
    payload: { reportId },
  });

export const getCustomerList = (page, limit = 10, ids) =>
  ActionDispatcher(
    downloadClientList.bind(null, page, limit, ids),
    actionConstants.FETCH_CUSTOMER_LIST_SUCCESS,
    actionConstants.FETCH_CUSTOMER_LIST_FAILURE,
    actionConstants.FETCH_CUSTOMER_LIST_REQUEST
  );

export const resetCustomerList = () => (dispatch) =>
  dispatch({
    type: actionConstants.FETCH_CUSTOMER_LIST_CLEAR,
  });

export const getJobList = (page, limit = 10, params) =>
  ActionDispatcher(
    jobListApiCall.bind(null, params, page, limit),
    actionConstants.FETCH_JOBS_LIST_SUCCESS,
    actionConstants.FETCH_JOBS_LIST_FAILURE,
    actionConstants.FETCH_JOBS_LIST_REQUEST
  );

export const resetJobList = () => (dispatch) =>
  dispatch({
    type: actionConstants.FETCH_JOBS_LIST_CLEAR,
  });

export const getTeamList = (page, limit = 10) =>
  ActionDispatcher(
    labourListDropDown.bind(null, page, limit),
    actionConstants.FETCH_TEAM_LIST_SUCCESS,
    actionConstants.FETCH_TEAM_LIST_FAILURE,
    actionConstants.FETCH_TEAM_LIST_REQUEST
  );

export const resetTeamList = () => (dispatch) =>
  dispatch({
    type: actionConstants.FETCH_TEAM_LIST_CLEAR,
  });

export const getReportTemplateList = (page, limit = 10) =>
  ActionDispatcher(
    reportTemplateListApiCall.bind(null, page, limit),
    actionConstants.FETCH_REPORTS_TEMPLATE_LIST_SUCCESS,
    actionConstants.FETCH_REPORTS_TEMPLATE_LIST_FAILURE,
    actionConstants.FETCH_REPORTS_TEMPLATE_LIST_REQUEST
  );

export const resetReportTemplateList = () => (dispatch) =>
  dispatch({
    type: actionConstants.FETCH_REPORTS_TEMPLATE_LIST_CLEAR,
  });

export const getReportListByJobIds = (ids = []) =>
  ActionDispatcher(
    reportListByJobIdsApiCall.bind(null, ids),
    actionConstants.FETCH_REPORTS_LIST_BY_JOB_IDS_SUCCESS,
    actionConstants.FETCH_REPORTS_LIST_BY_JOB_IDS_FAILURE,
    actionConstants.FETCH_REPORTS_LIST_BY_JOB_IDS_REQUEST
  );

export const resetReportListByJobIds = () => (dispatch) =>
  dispatch({
    type: actionConstants.FETCH_REPORTS_LIST_BY_JOB_IDS_CLEAR,
  });

export const getReportListByCustomer = (
  page,
  limit = 10,
  searchText,
  reportType,
  reportCategory,
  CustomerNameSort,
  startDate,
  endDate,
  status,
  createdFor
) =>
  ActionDispatcher(
    reportsListApiCall.bind(
      null,
      page,
      limit,
      searchText,
      reportType,
      reportCategory,
      CustomerNameSort,
      startDate,
      endDate,
      status,
      createdFor
    ),
    actionConstants.FETCH_REPORTS_LIST_BY_CUSTOMER_SUCCESS,
    actionConstants.FETCH_REPORTS_LIST_BY_CUSTOMER_FAILURE,
    actionConstants.FETCH_REPORTS_LIST_BY_CUSTOMER_REQUEST
  );

export const resetReportListByCustomer = () => (dispatch) =>
  dispatch({
    type: actionConstants.FETCH_REPORTS_LIST_BY_CUSTOMER_CLEAR,
  });

export const getDeviceList = (page, limit = 10, id) =>
  ActionDispatcher(
    clientDeviceList.bind(null, page, limit, id),
    actionConstants.FETCH_DEVICE_LIST_SUCCESS,
    actionConstants.FETCH_DEVICE_LIST_FAILURE,
    actionConstants.FETCH_DEVICE_LIST_REQUEST
  );

export const resetDeviceList = () => (dispatch) =>
  dispatch({
    type: actionConstants.FETCH_DEVICE_LIST_CLEAR,
  });

export const getInnerDeviceList = (page, limit = 10, id) =>
  ActionDispatcher(
    clientInnerDeviceList.bind(null, page, limit, id),
    actionConstants.FETCH_INNER_DEVICE_LIST_SUCCESS,
    actionConstants.FETCH_INNER_DEVICE_LIST_FAILURE,
    actionConstants.FETCH_INNER_DEVICE_LIST_REQUEST
  );

export const resetInnerDeviceList = () => (dispatch) =>
  dispatch({
    type: actionConstants.FETCH_INNER_DEVICE_LIST_CLEAR,
  });

export const getPartList = (page, limit) =>
  ActionDispatcher(
    partListDropDown.bind(null, page, limit, ''),
    actionConstants.FETCH_PART_LIST_SUCCESS,
    actionConstants.FETCH_PART_LIST_FAILURE,
    actionConstants.FETCH_PART_LIST_REQUEST
  );

export const resetPartList = () => (dispatch) =>
  dispatch({
    type: actionConstants.FETCH_PART_LIST_CLEAR,
  });

export const createReportEntries = () => (dispatch) =>
  dispatch({
    type: actionConstants.CREATE_REPORT_ENTRIES,
  });

export const deleteReportEntry = (reportEntryId) => (dispatch) =>
  dispatch({
    type: actionConstants.DELETE_REPORT_ENTRY,
    payload: { reportEntryId },
  });

export const resetReportEntries = () => (dispatch) =>
  dispatch({
    type: actionConstants.RESET_REPORT_ENTRIES,
  });

export const fillJobDetails = (reportEntryId, jobDetails) => (dispatch) =>
  dispatch({
    type: actionConstants.FILL_JOB_DETAILS,
    payload: { jobDetails, reportEntryId },
  });

export const addCustomQuestionOffline =
  (reportEntryId, questionDetails) => (dispatch) =>
    dispatch({
      type: actionConstants.ADD_CUSTOM_QUESTION_OFFLINE,
      payload: { ...questionDetails, reportEntryId },
    });

export const addPart = (reportEntryId, partDetails) => (dispatch) =>
  dispatch({
    type: actionConstants.ADD_PART,
    payload: { partDetails, reportEntryId },
  });

export const deletePart = (reportEntryId, partIndex) => (dispatch) =>
  dispatch({
    type: actionConstants.DELETE_PART,
    payload: { partIndex, reportEntryId },
  });

export const addLabour = (reportEntryId, labourDetails) => (dispatch) =>
  dispatch({
    type: actionConstants.ADD_LABOUR,
    payload: { labourDetails, reportEntryId },
  });

export const deleteLabour = (reportEntryId, labourIndex) => (dispatch) =>
  dispatch({
    type: actionConstants.DELETE_LABOUR,
    payload: { labourIndex, reportEntryId },
  });

export const setReportSelectedToUpload = (reportId) => (dispatch) =>
  dispatch({
    type: actionConstants.SET_REPORT_ID_TO_UPLOAD,
    payload: { reportId },
  });

export const setUpdatedAnswersReportData = (reportDetail) => (dispatch) =>
  dispatch({
    type: actionConstants.FETCH_REPORT_DETAIL_SUCCESS,
    payload: {
      ...reportDetail,
    },
  });

export const setUpdatedAnswersReportDevices = (innerDevices) => (dispatch) =>
  dispatch({
    type: actionConstants.GET_DEVICES_FOR_REPORT_SUCCESS,
    payload: {
      docs: [...innerDevices],
      page: 1,
      pages: 1,
      total: innerDevices.length,
    },
  });

/**
 * Creates report entry for the new inspection report that is created offline
 * @param {object} reportDetail
 */
export const createReportEntryForNewReport = (reportDetail) => (dispatch) =>
  dispatch({
    type: actionConstants.CREATE_REPORT_ENTRY_FOR_NEW_INSPECTION_REPORT,
    payload: {
      ...reportDetail,
    },
  });

export const uploadSignatures = (signatureFormData) =>
  ActionDispatcher(
    ReportsFileUpload.bind(null, signatureFormData),
    actionConstants.UPLOAD_SIGNATURE_SUCCESS,
    actionConstants.UPLOAD_SIGNATURE_FAILURE,
    actionConstants.UPLOAD_SIGNATURE_REQUEST
  );

export const uploadSignaturesReset = () => (dispatch) =>
  dispatch({
    type: actionConstants.UPLOAD_SIGNATURE_RESET,
  });

export const setSignatureType = (signatureType) => (dispatch) =>
  dispatch({
    type: actionConstants.SET_SIGNATURE_TYPE,
    signatureType,
  });

export const getQuestionJsonDefaultReports = (payload) =>
  ActionDispatcher(
    questionJsonDefaultReports.bind(null, payload),
    actionConstants.FETCH_QUESTION_JSON_DEFAULT_SUCCESS,
    actionConstants.FETCH_QUESTION_JSON_DEFAULT_FAILURE,
    actionConstants.FETCH_QUESTION_JSON_DEFAULT_REQUEST
  );

export const resetQuestionJsonDefaultReports = () => (dispatch) =>
  dispatch({
    type: actionConstants.FETCH_QUESTION_JSON_DEFAULT_RESET,
  });
