import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { yearMonthFormat, dateFormat } from '../../utils/Constants';

/**
 * Renders each row of table in service due table.
 * @param {*} props
 */
const ServiceDueTableRow = (props) => {
  const {type, location, floor, manufacturer, serialNumber, size, dueFor, dueDate, coveredAt } =
    props.row;
  let date = props.isServiceDone === true ? coveredAt : dueDate;
  const dueDateFormatted = moment(date, dateFormat ).format( yearMonthFormat );


  return (
    <tr>
      <td style={ {width:"10%"} }>{type}</td>
      <td style={ {width:"25%"} }>{location}</td>
      <td style={ {width:"10%"} }>{floor}</td>
      <td style={ {width:"12%"} }>{manufacturer}</td>
      <td style={ {width:"13%"} }>{serialNumber}</td>
      <td style={ {width:"10%"} }>{size}</td>
      <td style={ {width:"10%"} }>{dueFor}</td>
      <td style={ {width:"10%"} }>{dueDateFormatted}</td>
    </tr>
  );
};

ServiceDueTableRow.propTypes = {
  row: PropTypes.object,
  isServiceDone: PropTypes.bool,
};

export default ServiceDueTableRow;
